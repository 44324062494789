<template>
  <div>
    <div
      class="d-flex align-items-center justify-content-center borderColor borderColorBackground"
    >
      <div style="margin-right: 20px">
        選擇INV:
        <select
          v-model="tableQueryParams.tableQuerySelectNum"
          style="width: 90px; height: 32px"
        >
          <option
            class="text-center"
            v-for="(i, idx) in selectOptions"
            :key="idx"
            :value="i.value"
          >
            {{ i.text }}
          </option>
        </select>
      </div>
      <div>
        選擇日期:
        <date-picker
          v-model="tableQueryParams.tableQueryDate"
          placeholder=""
          format="YYYY-MM-DD"
          type="date"
          style="width: 140px"
          :disabled-date="disabledDate"
        />
      </div>
      <div class="download-confirm-wrapper">
        <button
          class="confirmButton"
          style="margin: 5px"
          @click="confirmSearch"
        >
          <b-spinner small v-if="tableLoading"></b-spinner>
          <span style="margin-left: 2px" v-if="!tableLoading">{{
            $t("deviceHistory.confirm")
          }}</span>
        </button>
        <button
          class="confirmButton"
          style="margin: 5px"
          @click="clickDownloadCsv"
        >
          {{ $t("dataAnalysis.download") }}
        </button>
      </div>
    </div>
    <div class="borderColor" style="margin-top: 20px">
      <div class="text-center titlebackgrondstyle">
        <div class="inv-everyMin mt-4 highcharts-title">
          {{ $t("deviceHistory.specifiedINVInfoPerMin") }}: {{ tableTitle }}
        </div>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          style="width: 100px"
        ></b-form-select>
      </div>
      <b-alert
        v-if="!tableLoading && !hasTableData"
        show
        variant="success"
        style="text-align: center; font-weight: 700"
        >目前暫無資料或該設備異常，請重新選擇日期或設備
      </b-alert>
      <b-table
        :items="tableData"
        class="tableStyle"
        sticky-header
        :fields="tableField"
        hover
        :dark="dark"
        :head-variant="tableThColor"
        :responsive="true"
        :sort-by="sortBy"
        sort-icon-left
        :per-page="perPage"
        :current-page="currentPage"
        :busy="tableLoading"
        :sortDesc="sortDesc"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner
              class="align-middle"
              style="margin-right: 3px"
            ></b-spinner>
            <strong>讀取中</strong>
          </div>
        </template>
      </b-table>
    </div>
    <b-pagination
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="rows"
      class="mb-5"
      style="margin-top: 10px"
    ></b-pagination>
  </div>
</template>
<script>
import {
  BFormSelect,
  BTable,
  BPagination,
  BSpinner,
  BAlert
} from 'bootstrap-vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import downloadCsv from 'download-csv'
import i18n from '../lang/lang'
export default {
  name: 'devicehistory',
  components: {
    BFormSelect,
    BTable,
    BPagination,
    BSpinner,
    BAlert
  },
  data () {
    return {
      sortBy: '時間',
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      tableQueryParams: {
        tableQueryDate: new Date(),
        tableQuerySelectNum: 1
      },
      tableLoading: false,
      sortDesc: true,
      tableTitle: ''
    }
  },
  computed: {
    ...mapState('deviceHistory', ['tableField', 'selectOptions', 'tableData']),
    ...mapGetters('deviceHistory', ['hasTableData']),
    ...mapState(['dark', 'tableThColor']),
    rows () {
      return this.tableData.length
    }
  },
  methods: {
    ...mapActions('deviceHistory', [
      'handleTableField',
      'handleSelectOptions',
      'fetchTableData'
    ]),
    disabledDate (date) {
      const today = new Date()
      today.setDate(today.getDate())
      today.setHours(0, 0, 0, 0)
      return date > new Date(today.getTime())
    },
    confirmSearch () {
      this.tableLoading = true
      this.tableTitle =
        'INV' + ('0' + this.tableQueryParams.tableQuerySelectNum).slice(-2)
      this.fetchTableData(this.tableQueryParams).then((res) => {
        this.tableLoading = false
      })
    },
    clickDownloadCsv () {
      // remove _cellVariants
      const downloadData = this.tableData.map((obj) => {
        const { _cellVariants, ...newObject } = obj
        return newObject
      })
      downloadCsv(downloadData, {}, '裝置資訊表.csv')
    },
    tableLang () {
      const deviceHistoryTableTitle = [
        'Time',
        'frequency',
        'abVoltage',
        'bcVoltage',
        'caVoltage',
        'Aphasecurrent',
        'Bphasecurrent',
        'Cphasecurrent'
      ]
      for (let i = 0; i < deviceHistoryTableTitle.length; i++) {
        if (this.tableField[i]) {
          this.tableField[i].label = i18n.t(
            `deviceHistory.tableTitle.${deviceHistoryTableTitle[i]}`
          )
        }
      }
      for (
        let i = deviceHistoryTableTitle.length;
        i < this.tableField.length;
        i++
      ) {
        if (this.tableField[i]) {
          let str = this.tableField[i].key.replace('MTTP', 'PV')
          let changelangstr = str.replace('電壓', ' ').replace('電流', ' ')
          this.tableField[i].label = i18n.locale === 'en' ? changelangstr : str
        }
      }
    }
  },
  created () {
    this.confirmSearch()
    this.handleTableField()
    this.handleSelectOptions()
    this.tableLang()
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/DeviceHistory.scss";
</style>
